import React, { useEffect, useState } from 'react'
import InfoBox from '../../infoBox/InfoBox'
import styles from "./Home.module.scss"
import { AiFillDollarCircle } from "react-icons/ai"
import { BsCart4 } from "react-icons/bs"
import { FaCartArrowDown } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux'
import { selectProducts, STORE_PRODUCTS } from '../../../redux/slice/productSlice'
import { CALC_TOTAL_ORDER_AMOUNT, selectOrderHistory, selectTotalOrderAmount, STORE_ORDERS } from '../../../redux/slice/orderSlice'
import useFetchCollection from '../../../customHook/useFetchCollection'
import Chart from '../../chart/Chart'
import dayjs from 'dayjs'

// Icon
const earningIcon = <AiFillDollarCircle size={30} color ="#b624ff"/>
const productIcon = <BsCart4 size={30} color ="#1f93ff"/>
const ordersIcon = <FaCartArrowDown size={30} color ="orangered"/>

const Home = () => {
  const [storeSelected, setStoreSelected] = useState('All');
  const [timeSelected, setTimeSelected] = useState('today');
  const [storeRevenue, setStoreRevenue] = useState(0);
  const [storeItems, setStoreItems] = useState(0);
  const [storeOrder, setStoreOrder] = useState(0);
  const [cardMethod, setCardMethod] = useState(0);
  const [qrMethod, setQrMethod] = useState(0);
  const [cashMethod, setCashMethod] = useState(0);
  const products = useSelector(selectProducts)
  const orders = useSelector(selectOrderHistory)
  const totalOrderAmount = useSelector(selectTotalOrderAmount)

  const fbProducts = useFetchCollection("products")
  const {data} = useFetchCollection("orders")

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(STORE_PRODUCTS({
      products: fbProducts.data
    }))
    dispatch(STORE_ORDERS(data))
    dispatch(CALC_TOTAL_ORDER_AMOUNT())
  },[dispatch, data, fbProducts])

  useEffect(() => {
    if (orders && products) {
      calculateStoreRevenue();
      calculateItemQty();
      calculateOrderQty();
    }
  }, [orders, products, storeSelected, timeSelected])
  
  const calculateStoreRevenue = () => {
    const filteredOrder = storeSelected === 'All' ? orders : orders.filter(item => item.branch === storeSelected)
    if (timeSelected === 'All') {
      const array = [];
      const card = [];
      const qr = [];
      const cash = [];
      filteredOrder.map((item) => {
        const {orderAmount, paymentMethod} = item
        array.push(orderAmount)
        if (paymentMethod === 'Stripe') {
          card.push(orderAmount)
        } else if (paymentMethod === 'qr') {
          qr.push(orderAmount)
        } else if (paymentMethod === 'cod') {
          cash.push(orderAmount)
        }
      });
      const totalAmount = array.reduce((a, b) => {
        return a + b
      }, 0);
      const totalAmountCard = card.reduce((a, b) => {
        return a + b
      }, 0);
      const totalAmountQr = qr.reduce((a, b) => {
        return a + b
      }, 0);
      const totalAmountCash = cash.reduce((a, b) => {
        return a + b
      }, 0);
      setStoreRevenue(totalAmount);
      setCardMethod(totalAmountCard);
      setQrMethod(totalAmountQr);
      setCashMethod(totalAmountCash);
    } else {
      const array = []
      const card = [];
      const qr = [];
      const cash = [];
      filteredOrder.map((item) => {
        const {createdAt, orderAmount, paymentMethod} = item
        const docDate = dayjs(createdAt.toDate()).startOf('day');
        const compareDate = timeSelected === 'today' ? dayjs().startOf('day') 
                            : timeSelected === 'yesterday' ? dayjs().subtract(1, 'day').startOf('day')
                            : timeSelected === 't-2' ? dayjs().subtract(2, 'day').startOf('day')
                            : timeSelected === 't-3' ? dayjs().subtract(3, 'day').startOf('day') 
                            : timeSelected === 't-4' ? dayjs().subtract(4, 'day').startOf('day') 
                            : timeSelected === 't-5' ? dayjs().subtract(5, 'day').startOf('day') 
                            : timeSelected === 't-6' ? dayjs().subtract(6, 'day').startOf('day') 
                            : timeSelected === 't-7' ? dayjs().subtract(7, 'day').startOf('day') 
                            : null;
        const isSameDay = docDate.isSame(compareDate, 'day');
        if (isSameDay) {
          array.push(orderAmount)
          if (paymentMethod === 'Stripe') {
            card.push(orderAmount)
          } else if (paymentMethod === 'qr') {
            qr.push(orderAmount)
          } else if (paymentMethod === 'cod') {
            cash.push(orderAmount)
          }
        }
      });
      const totalAmount = array.reduce((a, b) => {
        return a + b
      }, 0);
      const totalAmountCard = card.reduce((a, b) => {
        return a + b
      }, 0);
      const totalAmountQr = qr.reduce((a, b) => {
        return a + b
      }, 0);
      const totalAmountCash = cash.reduce((a, b) => {
        return a + b
      }, 0);
      setStoreRevenue(totalAmount);
      setCardMethod(totalAmountCard);
      setQrMethod(totalAmountQr);
      setCashMethod(totalAmountCash);
    }

  }

  const calculateItemQty = () => {
    if (storeSelected === 'All') {
      setStoreItems(products.length);
    } else {
      const filteredProduct = products.filter(item => {
        const {stockControl} = item;
        const findBranchIndex = stockControl.findIndex(item => item.branch === storeSelected);
        return (
          stockControl[findBranchIndex]?.qty > 0
        )
      });
      setStoreItems(filteredProduct.length);
    }
  }

  const calculateOrderQty = () => {
    if (storeSelected === 'All') {
      if (timeSelected === 'All') {
        setStoreOrder(orders.length)
      } else {
        const filteredOrder = orders.filter(item => {
          const {createdAt} = item;
          const docDate = dayjs(createdAt.toDate()).startOf('day');
          const compareDate = timeSelected === 'today' ? dayjs().startOf('day') 
                              : timeSelected === 'yesterday' ? dayjs().subtract(1, 'day').startOf('day')
                              : timeSelected === 't-2' ? dayjs().subtract(2, 'day').startOf('day')
                              : timeSelected === 't-3' ? dayjs().subtract(3, 'day').startOf('day') 
                              : timeSelected === 't-4' ? dayjs().subtract(4, 'day').startOf('day') 
                              : timeSelected === 't-5' ? dayjs().subtract(5, 'day').startOf('day') 
                              : timeSelected === 't-6' ? dayjs().subtract(6, 'day').startOf('day') 
                              : timeSelected === 't-7' ? dayjs().subtract(7, 'day').startOf('day') 
                              : null;
          const isSameDay = docDate.isSame(compareDate, 'day');
          return (
            isSameDay
          )
        });
        setStoreOrder(filteredOrder.length);
      }
    } else {
      if (timeSelected === 'All') {
        const filteredOrder = orders.filter(item => item.branch === storeSelected)
        setStoreOrder(filteredOrder.length);
      } else {
        const filteredOrder = orders.filter(item => {
          const {createdAt, branch} = item;
          const docDate = dayjs(createdAt.toDate()).startOf('day');
          const compareDate = timeSelected === 'today' ? dayjs().startOf('day') 
                              : timeSelected === 'yesterday' ? dayjs().subtract(1, 'day').startOf('day')
                              : timeSelected === 't-2' ? dayjs().subtract(2, 'day').startOf('day')
                              : timeSelected === 't-3' ? dayjs().subtract(3, 'day').startOf('day') 
                              : timeSelected === 't-4' ? dayjs().subtract(4, 'day').startOf('day') 
                              : timeSelected === 't-5' ? dayjs().subtract(5, 'day').startOf('day') 
                              : timeSelected === 't-6' ? dayjs().subtract(6, 'day').startOf('day') 
                              : timeSelected === 't-7' ? dayjs().subtract(7, 'day').startOf('day') 
                              : null;
          const isSameDay = docDate.isSame(compareDate, 'day');
          return (
            isSameDay && branch === storeSelected
          )
        });
        setStoreOrder(filteredOrder.length);
      }
    }

  }

  const handleSelectStore = (e) => {
    setStoreSelected(e);
  }

  const handleSelectTime = e => {
    setTimeSelected(e);
  }

  return (
    <div className={styles.home}>
      <p className={styles.title}>DASHBOARD</p>
      <div className={styles.storeselectcontainer}>
        <p className={storeSelected === 'All' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectStore('All')}>
            ALL
        </p>
        <p className={storeSelected === 'kallapaprueak' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectStore('kallapaprueak')}>
            KALLAPAPRUEAK
        </p>
        <p className={storeSelected === 'bangbon' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectStore('bangbon')}>
            BANGBON
        </p> 
        <p className={storeSelected === 'westgate' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectStore('westgate')}>
            WESTGATE
        </p>
        <p className={storeSelected === 'iconsiam' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectStore('iconsiam')}>
            ICONSIAM
        </p>
      </div>
      <div className={styles.timeselectcontainer}>
        <p className={timeSelected === 'All' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectTime('All')}>
            ALL
        </p>
        <p className={timeSelected === 'today' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectTime('today')}>
            TODAY
        </p>
        <p className={timeSelected === 'yesterday' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectTime('yesterday')}>
            YESTERDAY
        </p>
        <p className={timeSelected === 't-2' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectTime('t-2')}>
            T-2
        </p>
        <p className={timeSelected === 't-3' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectTime('t-3')}>
            T-3
        </p>
        <p className={timeSelected === 't-4' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectTime('t-4')}>
            T-4
        </p>
        <p className={timeSelected === 't-5' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectTime('t-5')}>
            T-5
        </p>
        <p className={timeSelected === 't-6' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectTime('t-6')}>
            T-6
        </p>
        <p className={timeSelected === 't-7' ? styles.titleactive : styles.titlenotactive} 
          onClick={() => handleSelectTime('t-7')}>
            T-7
        </p>
      </div>

      <div>
        <div className={styles["info-box"]}>
          <InfoBox cardClass={`${styles.card} ${styles.card1}`} title={"REVENUE"} count={`${storeRevenue?.toLocaleString('TH')} THB`} icon={earningIcon}/>
          <InfoBox cardClass={`${styles.card} ${styles.card2}`} title={"ITEMS"} count={storeItems} icon={productIcon}/>
          <InfoBox cardClass={`${styles.card} ${styles.card3}`} title={"ORDERS"} count={storeOrder} icon={ordersIcon}/>
        </div>
        <div className={styles.paymentinfo}>
          <p className={styles.titlepayment}>PAYMENT METHOD</p>
          <div className={styles.descpayment}>
            <p className={styles.desctitlepayment}>CARD</p>
            <p>{cardMethod?.toLocaleString('th')} THB</p>
          </div>
          <div className={styles.descpayment}>
            <p className={styles.desctitlepayment}>QR</p>
            <p>{qrMethod?.toLocaleString('th')} THB</p>
          </div>
          <div className={styles.descpayment}>
            <p className={styles.desctitlepayment}>CASH</p>
            <p>{cashMethod?.toLocaleString('th')} THB</p>
          </div>
        </div>
        <div>
          <Chart />
        </div>
      </div>

    </div>
  )
}

export default Home