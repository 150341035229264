import React, { useEffect, useState } from 'react'
import styles from "./Header.module.scss"
import { onAuthStateChanged } from 'firebase/auth'
import { auth, db } from '../../firebase/config'
import { useDispatch } from 'react-redux'
import { SET_ACTIVE_USER, REMOVE_ACTIVE_USER} from '../../redux/slice/authSlice'
import { Timestamp, doc, getDoc, setDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'

const Header = () => {

  const [displayName, setdisplayName] = useState("")
  const [scrollPage, setScrollPage] = useState(false)


  const dispatch = useDispatch()
  const navigate = useNavigate();

  const fixNavbar = () => {
    if (window.scrollY > 50) {
      setScrollPage(true)
    } else {
      setScrollPage(false)
    }
  }
  window.addEventListener("scroll", fixNavbar)

  const getUserInfo = async (id, userEmail, userName) => {
    try {
    const docRef = doc(db, 'users', id)
    const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const obj = {
         id: id,
         ...docSnap.data()
        }
        try {
          await setDoc(doc(db, "users", id), {
              platform: 'website',
              editedAt: Timestamp.now().toDate(),
          }, {merge: true})
          .then(() => {
            dispatch(
              SET_ACTIVE_USER({
                email: obj.userEmail,
                userName: obj.userName,
                userID: id,
              }),
            );
          });
        } catch (error) {
            console.log(error);
        }

      }
      else {
        console.log('User not found.');
        try {
            await setDoc(doc(db, "users", id), {
                userId: id,
                userEmail: userEmail,
                userName: userName,
                platform: 'website',
                createdAt: Timestamp.now().toDate(),
            })
            .then(() => {
                dispatch(
                SET_ACTIVE_USER({
                    email: userEmail,
                    userName: userName,
                    userID: id,
                }),
                );
            });
        } catch (error) {
            console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Monitor currently sign in user
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.displayName === null) {
          if (user?.email !== null) {
            const userName = '@' + 'user-' + user.uid.slice(0, 10);
            getUserInfo(user.uid, user.email, userName);
          } else if (user.phoneNumber) {
            const userName = '@' + 'user-' + user.uid.slice(0, 10);
            const phoneSlice = user?.phoneNumber.slice(1);
            getUserInfo(user.uid, phoneSlice + '@neko.co.th', userName);
          }
        }
        dispatch(SET_ACTIVE_USER({
          email: user.email ? user.email : null,
          userName: user.displayName ? user.displayName : displayName,
          userID: user.uid,
          phoneNumber: user.phoneNumber ? user.phoneNumber : null,
        }))
        navigate('/admin');

      } else {
        setdisplayName("")
        dispatch(REMOVE_ACTIVE_USER())
        navigate('/login')
      }
    })
  },[dispatch, displayName])

  
  return (
    <>
    </>
  )
}

export default Header