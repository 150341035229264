import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck , ReCaptchaV3Provider} from "firebase/app-check";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: "neko-bake.firebaseapp.com",
  databaseURL: "https://neko-bake-default-rtdb.firebaseio.com",
  projectId: "neko-bake",
  storageBucket: "neko-bake.appspot.com",
  messagingSenderId: "997642547173",
  appId: "1:997642547173:web:7520c827765840f3adc90c",
  measurementId: "G-BCZ1ZV1D0W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app)
export const storage = getStorage(app);

export default app

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdHhLghAAAAALVqfYQOAQTztvObfWorCDvjDkzq'),
  isTokenAutoRefreshEnabled: true,
});
